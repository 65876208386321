import React, { useState, useEffect } from "react"
import Swipe from "react-swipeable-views"
import Image from "gatsby-image"
import styled from "styled-components"

import { SIZES, COLORS } from "src/layout/constants"

const Container = styled.section`
  position: relative;
`
const Slide = styled.div`
  position: relative;
  .gatsby-image-wrapper {
    height: calc(100vh - ${SIZES.header}px);
  }
`
const Content = styled.div`
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  @media (max-width: 799px) {
    top: 10%;
  }
`
const Text = styled.div<{ $color: string }>`
  margin: 0 auto;
  padding: 0 ${SIZES.margin}px;
  max-width: ${SIZES.maxWidth}px;
  color: ${({ $color }) => $color};
  h1,
  h3 {
    max-width: 800px;
  }
  h1 {
    margin-top: 0.7em;
    font-size: 5em;
  }
  h3 {
    line-height: 1.6em;
    font-weight: 500;
  }
  @media (max-width: 799px) {
    padding: 0 ${SIZES.mobileMargin}px;
    h1 {
      font-size: 4em;
    }
  }
`
const Dots = styled.div`
  position: absolute;
  bottom: 12px;
  right: 24px;
  @media (pointer: coarse) {
    display: none;
  }
`
const Dot = styled.button<{ $active: boolean }>`
  width: 20px;
  height: 20px;
  margin: 8px;
  padding: 0;
  border-radius: 10px;
  background: ${({ $active }) => ($active ? COLORS.grey : COLORS.white)};
`

interface Props {
  slides: HomePhoto[]
}

const Carousel = ({ slides }: Props) => {
  const [index, setIndex] = useState(0)
  const [clicked, setClicked] = useState(false)

  const handleManual = (idx: number) => {
    setClicked(true)
    setIndex(idx)
  }

  const handleClick = (current: number) => () => {
    handleManual(current)
  }

  useEffect(() => {
    if (clicked) {
      return
    }

    const next = () => {
      setIndex(index < slides.length - 1 ? index + 1 : 0)
    }

    const timeout = setTimeout(next, 5000)

    return () => {
      clearTimeout(timeout)
    }
  }, [slides.length, clicked, index])

  return (
    <Container>
      <Swipe index={index} onChangeIndex={handleManual} resistance>
        {slides.map(({ photo, portrait, title, subtitle, color }, i) => (
          <Slide key={i}>
            <Image
              fluid={[photo.fluid, { ...portrait.fluid, media: "(orientation: portrait)" }]}
              loading={i <= index + 1 ? "eager" : "lazy"}
            />
            <Content>
              <Text $color={color === "blanc" ? COLORS.white : COLORS.black}>
                <h1>{title}</h1>
                <h3>{subtitle}</h3>
              </Text>
            </Content>
          </Slide>
        ))}
      </Swipe>
      <Dots>
        {slides.map((_, i) => (
          <Dot key={i} onClick={handleClick(i)} $active={i === index} />
        ))}
      </Dots>
    </Container>
  )
}

export default Carousel
