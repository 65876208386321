import React from "react"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import { FormattedMessage } from "react-intl"

import Layout from "src/layout"
import { COLORS, SIZES } from "src/layout/constants"
import { useLang } from "src/layout/root"
import { Section as BaseSection, Row, Column, Label, Text } from "src/components/styled"
import Carousel from "src/components/Carousel"
import { uppercase } from "src/components/Name"
import France from "src/svg/france.svg"
import Textile from "src/svg/textile.svg"
import Sound from "src/svg/sound.svg"
import Light from "src/svg/light.svg"
import Laser from "src/svg/laser.svg"
import Logo from "src/svg/logo.svg"

const Icons = styled(Row)`
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  white-space: pre-line;
  @media (min-width: 800px) {
    svg {
      width: 175px;
    }
  }
  @media (max-width: 649px) {
    ${Column} {
      flex: 0 1 calc(50% - 60px);
      min-width: 80px;
    }
  }
`
const FirstSection = styled.section`
  background-color: ${COLORS.light};
  ${Text} {
    margin: 0;
  }
  @media (min-width: 800px) {
    padding: ${SIZES.margin}px;
    ${Text} {
      margin-top: ${SIZES.margin}px;
    }
    ${Row}, ${Icons} {
      max-width: ${SIZES.maxWidth}px;
      margin: 0 auto;
    }
  }
`
const Section = styled(BaseSection)`
  @media (min-width: 800px) {
    ${Label} {
      margin-top: ${SIZES.margin}px;
    }
    ${Text} {
      margin-top: ${SIZES.margin * 2}px;
    }
  }
`
const FileLink = styled(Link)`
  display: inline-block;
  font-size: 1.2em;
  @media (min-width: 800px) {
    margin-top: ${SIZES.margin * 2}px;
  }
`
const SplashLink = styled(Link)`
  position: relative;
  display: block;

  @media (max-width: 599px) {
    .gatsby-image-wrapper {
      min-height: 100vw;
    }
  }
`
const Center = styled.div`
  position: absolute;
  top: 25%;
  left: ${SIZES.margin}px;
  right: ${SIZES.margin}px;
  text-align: center;
  color: ${COLORS.white};
  h1 {
    margin-top: 0.5em;
  }
`
const Figure = styled.figure`
  figcaption {
    margin-top: 10px;
    font-size: 0.75em;
    line-height: 1.5em;
    text-transform: uppercase;
    letter-spacing: 2px;
    white-space: pre-line;
  }
  margin: ${SIZES.mobileMargin}px 0 0;
  @media (min-width: 800px) {
    margin: ${SIZES.margin}px 0;
  }
`
const BottomSplashText = styled.div`
  position: absolute;
  top: 15%;
  left: 58%;
  right: ${SIZES.margin}px;
  max-width: 430px;
  @media (max-width: 999px) {
    top: 10%;
    svg {
      height: 20px;
    }
    h1 {
      font-size: 2em;
    }
  }
  @media (max-width: 599px) {
    ${Text} {
      display: none;
    }
  }
`

interface DataType {
  slides: { nodes: HomePhoto[] }
  lining: { childImageSharp: Media }
  flexible: { childImageSharp: Media }
  cutting: { childImageSharp: Media }
  light: { childImageSharp: Media }
}

const Home: GatsbyPage<DataType> = ({ data }) => {
  const { getNodes } = useLang()
  const slides = getNodes<HomePhoto>(data.slides)

  return (
    <Layout>
      <Carousel slides={slides} />
      <FirstSection>
        <Row>
          <Column>
            <FormattedMessage id="home.commitLabel" tagName={Label} />
            <FormattedMessage id="home.commitTitle" tagName="h1" />
          </Column>
          <Column>
            <FormattedMessage id="home.commitText" tagName={Text} values={uppercase} />
          </Column>
        </Row>
        <Icons>
          <Column>
            <France />
            <FormattedMessage id="home.france" tagName="p" />
          </Column>
          <Column>
            <Textile />
            <FormattedMessage id="home.textile" tagName="p" />
          </Column>
          <Column>
            <Sound />
            <FormattedMessage id="home.sound" tagName="p" />
          </Column>
          <Column>
            <Light />
            <FormattedMessage id="home.light" tagName="p" />
          </Column>
        </Icons>
      </FirstSection>
      <Section>
        <Row>
          <Column>
            <Link to="/inspirations/">
              <Image className="contrast" fluid={data.flexible.childImageSharp.fluid} />
            </Link>
          </Column>
          <Column>
            <FormattedMessage id="home.applicationsLabel" tagName={Label} />
            <FormattedMessage id="home.applicationsTitle" tagName="h1" />
            <FormattedMessage id="home.applicationsText" tagName={Text} />
            <FileLink to="/catalog/">
              <FormattedMessage id="home.catalog" />
            </FileLink>
          </Column>
        </Row>
      </Section>
      <Section>
        <SplashLink to="/products/" state={{ id: "lining" }}>
          <Image fluid={data.lining.childImageSharp.fluid} />
          <Center>
            <FormattedMessage id="home.wallLabel" tagName="h3" />
            <FormattedMessage id="home.wallTitle" tagName="h1" />
          </Center>
        </SplashLink>
      </Section>
      <Section>
        <Row>
          <Column>
            <FormattedMessage id="home.customLabel" tagName={Label} />
            <FormattedMessage id="home.customTitle" tagName="h1" />
            <FormattedMessage id="home.customText" tagName={Text} />
            <Figure>
              <Laser />
              <FormattedMessage id="home.laser" tagName="figcaption" />
            </Figure>
          </Column>
          <Column>
            <Link to="/products/" state={{ id: "custom" }}>
              <Image className="contrast" fluid={data.cutting.childImageSharp.fluid} />
            </Link>
          </Column>
        </Row>
      </Section>
      <Section>
        <SplashLink to="/about/">
          <Image fluid={data.light.childImageSharp.fluid} />
          <BottomSplashText>
            <Logo height={60} />
            <FormattedMessage id="home.reinventTitle" tagName="h1" />
            <FormattedMessage id="home.reinventText" tagName={Text} />
          </BottomSplashText>
        </SplashLink>
      </Section>
    </Layout>
  )
}

export default Home

export const query = graphql`
  query {
    slides: allContentfulPhotoAccueil(sort: { fields: position }) {
      nodes {
        node_locale
        title
        subtitle
        color
        photo {
          fluid(maxWidth: 1600, quality: 75) {
            ...GatsbyContentfulFluid
          }
        }
        portrait {
          fluid(maxWidth: 400, quality: 75) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
    lining: file(relativePath: { eq: "home/lining.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    flexible: file(relativePath: { eq: "home/flexible.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cutting: file(relativePath: { eq: "home/cutting.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    light: file(relativePath: { eq: "home/light.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
